.App {
  text-align: center;
  max-width: 750px;
  position: relative;
  margin: 0 auto;
  user-select: none;

background: #fef9f3;
}
body{
 background: rgb(237, 237, 237);
}


.App-content{
  width: 100%;
}


/*fonts*/

@font-face {
  font-family: 'HYQiHei-40S';
  /*src: url('../public/fonts/HYQiHei-40S.ttf');*/
  font-weight: normal;
  font-style: normal;
}