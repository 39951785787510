.project-block {
  position: relative;
  .btn{
    user-select: none;
  }
  .btn:hover{
    opacity: 0.7;
  }
  &.left{
    text-align: left;
    .info {
      min-width: 400px;
      position: absolute;
      top: 100px;
      right: 0;
      text-align: center;

      .text {
        margin-top: 16px;
        padding-right: 5px;

        font-size: 18px;
        line-height: 30px;
        color: #4e4c67;
        p{
          margin: 0;
        }
      }

      .btn {
        margin-top: 50px;
      }

    }
  }
  &.right{

    text-align: right;
    .info {
      min-width: 400px;
      position: absolute;
      top: 100px;
      left: 0;
      text-align: center;

      .text {
        margin-top: 16px;

        font-size: 18px;
        line-height: 30px;
        color: #4e4c67;
        p{
          margin: 0;
        }
      }

      .btn {
        margin-top: 50px;
      }

    }
  }

}