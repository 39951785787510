.event_title {
  font-size: 40px;
  padding: 50px 0 30px 45px;
  text-align: left;
  background-color: #fff;
  //border-bottom: 8px solid #f5f5f5;
  margin-bottom: 40px;
}

.event_node {



  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .event_year {
    width:85px;
    text-align: left;
    padding-left: 45px;
    margin-top: -8px;
  }
  .event_line{

  }

  .event_content {
    .event_content_html{
      margin-top: -18px;
    }


    font-size: 22px;
    line-height: 44px;
    width: 600px;
    padding-bottom:20px;
    color: #5a5a5a;


    img{
      margin: 0 auto!important;
    }
    p:first-child{
      margin-top: 0;
    }

    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 9px;
      height: 9px;
      left: -7px;
      top: 0px;
      border-radius: 8px;
      border: 2px solid #fff;

    }

    &:after {
      position: absolute;
      content: '';
      width: 20px;
      height: 1px;
      left: 0px;
      top: 5px;

    }

  }

  &:last-child {
    .event_line {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 9px;
        height: 9px;
        left: -4px;
        bottom: 0px;
        border-radius: 8px;

      }
    }
  }
}