.pop-up-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  max-width: 750px;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.3);

  padding: 30px;
  box-sizing: border-box;

  .closeBtn {
    position: absolute;
    top: 30px;
    right: 39px;
    padding: 20px;
    background-color: #fff;
    z-index: 1000;
    border-radius: 0 0 0 30px;
    img {
      width:63px;
      height: 19px;
    }
  }

  .pop-up-content-all {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    .pop-up-content {
      padding-top: 30px;


      position: relative;
      width: 680px;


      box-sizing: border-box;

      text-align: left;
      font-size: 24px;
      background-color: #fff;


      h1, h2, h3, h4, h5, h6 {
        padding: 0 45px;
      }

      ol,ul {
        margin-left: 30px;
        box-sizing: border-box;
        font-size: 22px!important;
        p {
          padding: 20px 40px 20px 0px;
        }
      }

      p {
        padding: 0 45px;
        text-align: justify;


        box-sizing: border-box;
        line-height: 44px;
        font-size: 22px!important;
        color: #5a5a5a;

        img {
          box-sizing: border-box;
          max-width: 680px;
          margin: 0 -45px;
        }

        &.center {
          img {
            margin: 0 auto;
          }
        }

      }

    }
  }

}


$base-color-blue-green: #65a890;

.color-blue-green {
  //蓝绿色模版
  .pop-up-content-all {

    //border-top: 1px solid $base-color-blue-green;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-blue-green;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-blue-green;
  }

  .background-color {
    background-color: $base-color-blue-green;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-blue-green;
    }

  }

  .border-color {
    border-color: $base-color-blue-green;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-blue-green !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-blue-green;


    &:before {
      background-color: $base-color-blue-green;
      box-shadow: 0px 0px 1px 1px $base-color-blue-green;
    }
    &:after {
      background-color: $base-color-blue-green;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-blue-green;
      }
    }
  }
}
$base-color-orange: #e07b53;
.color-orange {
  //橙色模版
  .pop-up-content-all {
    //border-top: 1px solid $base-color-orange;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-orange;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-orange;
  }

  .background-color {
    background-color: $base-color-orange;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-orange;
    }

  }

  .border-color {
    border-color: $base-color-orange;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-orange !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-orange;


    &:before {
      background-color: $base-color-orange;
      box-shadow: 0px 0px 1px 1px $base-color-orange;
    }
    &:after {
      background-color: $base-color-orange;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-orange;
      }
    }
  }
}
$base-color-green: #96ad71;
.color-green {
  //绿色模版
  .pop-up-content-all {
    //border-top: 1px solid $base-color-green;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-green;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-green;
  }

  .background-color {
    background-color: $base-color-green;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-green;
    }

  }

  .border-color {
    border-color: $base-color-green;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-green !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-green;


    &:before {
      background-color: $base-color-green;
      box-shadow: 0px 0px 1px 1px $base-color-green;
    }
    &:after {
      background-color: $base-color-green;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-green;
      }
    }
  }
}
$base-color-yellow: #ecb066;
.color-yellow {
  //黄色模版
  .pop-up-content-all {
    //border-top: 1px solid $base-color-yellow;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-yellow;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-yellow;
  }

  .background-color {
    background-color: $base-color-yellow;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-yellow;
    }

  }

  .border-color {
    border-color: $base-color-yellow;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-yellow !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-yellow;


    &:before {
      background-color: $base-color-yellow;
      box-shadow: 0px 0px 1px 1px $base-color-yellow;
    }
    &:after {
      background-color: $base-color-yellow;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-yellow;
      }
    }
  }
}

$base-color-red: #de6153;
.color-red {
  //红色模版
  .pop-up-content-all {
    //border-top: 1px solid $base-color-red;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-red;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-red;
  }

  .background-color {
    background-color: $base-color-red;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-red;
    }

  }

  .border-color {
    border-color: $base-color-red;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-red !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-red;


    &:before {
      background-color: $base-color-red;
      box-shadow: 0px 0px 1px 1px $base-color-red;
    }
    &:after {
      background-color: $base-color-red;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-red;
      }
    }
  }
}

$base-color-gray: #4e4c67;
.color-gray {
  //灰色模版
  .pop-up-content-all {
    //border-top: 1px solid $base-color-gray;

    h1, h2, h3, h4, h5, h6 {
      color: $base-color-gray;
      font-weight: normal;
    }
  }

  .color {
    color: $base-color-gray;
  }

  .background-color {
    background-color: $base-color-gray;
  }

  .before_background-color {
    &::before {
      background-color: $base-color-gray;
    }

  }

  .border-color {
    border-color: $base-color-gray;
  }

  .swiper-pagination-bullet-active {
    background-color: $base-color-gray !important;
  }

  .event_node_content {
    border-left: 1px solid $base-color-gray;


    &:before {
      background-color: $base-color-gray;
      box-shadow: 0px 0px 1px 1px $base-color-gray;
    }
    &:after {
      background-color: $base-color-gray;
    }
  }
  .event_node:last-child {
    .event_line {
      &:before {
        background-color: $base-color-gray;
      }
    }
  }
}