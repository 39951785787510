.MediaReports{
  margin-top: -22px;
  background-color: #fff;
  padding: 150px 30px 50px;
  .container{
    border-style: solid ;
    border-width: 2px;
    padding: 30px 30px 30px;
    position: relative;
    .MediaReports-title{
      color: #5a5a5a;
      font-size: 40px;
      position: absolute;
      top:-25px;
      left:20px;
      background-color: #fff;
      padding: 0 10px;

    }
    .more_btn{
      color: #5a5a5a;
      font-size: 22px;
      padding: 0px 0px 20px 0px;
    }
    .report{
      padding: 20px 10px 20px 20px;

      .report-title{
        position: relative;
        line-height: 30px;
        &:before{
          position: absolute;
          content: '';
          width: 8px;
          height: 8px;
          left: -20px;
          top:10px;
          border-radius: 4px;
        }
        text-align: left;
        font-size: 22px;
        a{
          color: #5a5a5a;
          text-decoration:underline ;
          text-underline-offset: 8px;
        }
      }
      .report-info{
        margin-top: 10px;
        text-align: left;
        font-size: 18px;

          color: #5a5a5a;

      }
    }
  }

}