.Home {
  width: 750px;
  overflow: hidden;


  .blur-home{

    filter: blur(20px);

  }



  #smooth-wrapper {


  }

  #smooth-content {

  }
}
.loading{
  height: 100vh;
  padding: 40vh 0;
}