.pop-up-alert-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  max-width: 750px;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.3);

  padding: 30px;
  box-sizing: border-box;
  .pop-up-content-all {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}