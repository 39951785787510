.details_btn{
  padding: 8px 40px;
  color: #fff;
  font-size: 22px;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 4px 4px 2px rgba(0,0,0,0.1);
  margin-top: 10px;
  margin-bottom: 30px;

  &:hover{
    opacity: 0.7;
  }

}
.details_html{
  img{

      margin: 0 auto !important;

  }
}
