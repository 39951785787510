
.page-1 {
  height: 1130px;
  overflow-x: hidden;
  width: 750px;

  .background {
    //width: 100%;
    position: absolute;

    top: -105px;
    left: 0;
  }

  .info {
    z-index: 1;
    position: relative;
    .cloud{
      position: absolute;
      --animate-duration: 3s
    }
    .logo {
      position: relative;
      z-index: 1;
      padding-top: 9vw;

    }

    .title {
      padding-top: 5vw;
      position: relative;
      z-index: 1;
    }

    .man_dog {
      padding-top: 11vw;
      position: absolute;
      top: 580px;
      left: 28px;
      z-index: 1;
    }

    .car {
      padding-top: 12vw;
      position: absolute;
      top: 610px;
      right: 40px;
      z-index: 1;
    }

    .nav {
      padding-top: 1vw;
      position: absolute;
      top: 950px;
      left: 0;
      z-index: 1;
      width: 100%;
    }
  }
}
