.full-view{
    position: relative;
    width:100vw;
    height: 100vh;
    overflow: hidden;
}
.Home-video-background{
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index:-11;
}
.Home-Page{
    width:100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.Home-p1{
    /*横向*/
    justify-content:center;
    /*纵向*/
    align-items:center;
    color: white;
}
.Home-p1-title{
    font-size: 60px;
}

