.story_image{
  img{
    max-width: 100%;
    display: block;
  }

}
.story_title{
  font-size: 40px;
  padding: 50px 0 30px 40px;
  text-align: left;
  background-color: #fff;
}
.story_html{
  background-color: #f5f5f5;
  padding: 20px 0;


}